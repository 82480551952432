<template>
    <v-row class="mt-2 mx-1">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                    <div>
                        <span class="mx-2">Upload Signatory</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="mb-3">
                        <v-col cols="3">
                            <v-text-field
                                label="First Name"
                                hide-details="auto"
                                v-model="signatory.first_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Middle Name"
                                hide-details="auto"
                                v-model="signatory.middle_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Last Name"
                                hide-details="auto"
                                v-model="signatory.last_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Suffix"
                                hide-details="auto"
                                v-model="signatory.suffix"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            >
                            <v-text-field
                                label="Position"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="signatory.position"
                            ></v-text-field>
                        </v-col>  
                        <v-col cols="6">
                            <v-file-input
                                v-model="signatory.valid_id"
                                label="ID"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                        </v-file-input>
                        </v-col>
                    </v-row>
                    <v-row v-if="moa.moa_type == 'AVP'">
                        <v-col
                            cols="6"
                            sm="4"
                            md="4"
                            >
                            <v-text-field
                                label="ID Type"
                                outlined
                                dense
                                v-model="signatory.id_type"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>  
                        <v-col
                            cols="6"
                            sm="4"
                            md="4"
                            >
                            <v-text-field
                                label="ID Number"
                                outlined
                                dense
                                v-model="signatory.id_number"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>  
                        <v-col :cols="4">
                            <v-menu
                                v-model="signatory.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="signatory.id_expiry"
                                            label="ID Expiry"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                            outlined
                                            hide-details="auto"
                                        >
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="signatory.id_expiry"
                                    @input="signatory.menuIssued = false"
                                    hide-details="auto"
                                    >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            
                            <v-btn v-if="moa.moa_type == 'AVP' && signatory.first_name && signatory.middle_name && signatory.last_name && signatory.position && signatory.valid_id && signatory.id_expiry && signatory.id_type && signatory.id_number"
                            class="mb-4" style="float:right;" @click="uploadSignatory" outlined color="primary"><v-icon>mdi-plus</v-icon>Upload Signatory</v-btn>

                            <v-btn v-else-if="moa.moa_type == 'WU_SU-REP' &&signatory.first_name && signatory.middle_name && signatory.last_name && signatory.position && signatory.valid_id"
                            class="mb-4" style="float:right;" @click="uploadSignatory" outlined color="primary"><v-icon>mdi-plus</v-icon>Upload Signatory</v-btn>

                            <v-btn v-else-if="moa.moa_type == 'NONEX' &&signatory.first_name && signatory.middle_name && signatory.last_name && signatory.position && signatory.valid_id"
                            class="mb-4" style="float:right;" @click="uploadSignatory" outlined color="primary"><v-icon>mdi-plus</v-icon>Upload Signatory</v-btn>

                            <v-btn v-else
                            class="mb-4" style="float:right;" disabled outlined color="primary"><v-icon>mdi-plus</v-icon>Upload Signatory</v-btn>

                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';
import _ from 'lodash';
export default {
    props: ["item"],
    computed: {
    ...mapGetters({
            signatory: 'salesProspecting/signatory',
            moa: 'salesProspecting/moa',
            existingSignatoryUploaded: 'salesProspecting/existingSignatoryUploaded',
        }),
    },
    methods: {
        addSignatory(){
           console.log('Hello')
        },
        async uploadSignatory(){
            console.log(this.signatory)
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
            var convertedFormData = jsonToFormData(this.signatory, options)
            const payload = {id: this.item, payload: convertedFormData}
            const res = await this.$store.dispatch('salesProspecting/doUploadSignatory', {payload})
			if (res.status === 200){
				this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
				this.realoadSignatory()
                const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item)
                console.log(res)
			}
        },
        async realoadSignatory () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item)
			this.decodeSignatory(res.data.result[0].signatory)
			this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
		},
        decodeSignatory (resBod){
            console.log(resBod)
			let nonExpiry = {
                file: null,
            }
            let expiryMenu = {
				menuIssued: false,
                menuExpiry: false
			}
			resBod.forEach(function(e) {
				e.signatory_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiry)
                e.menu = _.clone(expiryMenu)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY', resBod)
		},
    }
}
</script>
