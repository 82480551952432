<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="existingSalesProspect"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id})">
                                    {{item.sp_primary_details.account_name}}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.status === 'Merged (IS)' && item.profile == 'complete'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.status === 'Merged (IS)' && item.profile == 'incomplete'"
                                        text
                                        color="primary"
                                        @click="openDialog(item)"
                                        >
                                        <v-icon>mdi-account-alert-outline</v-icon>
                                    </v-btn>

                                    <v-btn 
                                        v-if="(item.is_generate === 1) && currUser.user_role === 'encoder' && item.status !== 'Terminated'"
                                        text
                                        color="primary"
                                        small
                                        >
                                        <MOA :item="item.id"> </MOA>
                                    </v-btn>

                                    <v-btn 
                                        v-if="currUser.user_role === 'encoder' && item.status !== 'Terminated' && item.status !== 'Deletion Pending'"
                                        text
                                        color="red"
                                        small
                                        >
                                        <Termination :item="item.id"> </Termination>
                                    </v-btn>

                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-dialog
                v-model="dialog"
                width="900px"
                persistent
                >
                    <v-card>
                        <v-card-title>
                        <span class="text-h5" id="dialog_title">Account Information</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Account Name"
                                                hide-details="auto"
                                                v-model="salesProspectingForm.account_name"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Legan Entity Name"
                                                hide-details="auto"
                                                v-model="salesProspectingForm.legal_entity_name"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Trade Name"
                                                hide-details="auto"
                                                v-model="salesProspectingForm.trade_name"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined color="primary"
                            text
                            @click="cancel"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            v-if="salesProspectingForm.account_name && salesProspectingForm.legal_entity_name && salesProspectingForm.trade_name"
                            color="primary"
                            @click="completeProfile"
                        >
                            Complete Profile
                        </v-btn>
                        <v-btn
                            v-else
                            color="primary"
                            disabled
                        >
                            Complete Profile
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>

        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
import MOA from '../../components/modules/salesProspecting/MOA.vue';
import Termination from '../../components/modules/salesProspecting/Termination.vue';
import _ from 'lodash';
import jsonToFormData from 'json-form-data';


export default {
  name: 'Users',
  components:{
    MOA: MOA,
    Termination: Termination
  },
  data () {
    return {
        search: '',
        fab: false,
        salesProspecting: [],
        existingSalesProspect: [],
        headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Date Created',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Profile',
                sortable: true,
                value: 'profile'
            },
            {
                text: 'Status',
                sortable: true,
                value: 'status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        dialog: false,
        sp_no: ''

    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      salesProspectingForm: 'salesProspecting/salesProspectingForm',
    })
  },
  async mounted () {
    this.reloadList()
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM')
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM_INITIAL_UPLOAD')
    this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER_DEFAULT')
    this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_FILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_BRANCH_PERMIT_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_FINAL_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BSP_DEFAULT')
    this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BOD_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
    this.$store.commit('bsp/UNSET_BSP_ID')
    this.$store.commit('bsp/SET_DET_DETAILS_DEFAULT')
    this.$store.commit('bsp/SET_DEF_FILES_DEFAULT')
    this.$store.commit('bsp/DET_FILES_DEFAULT')
    this.$store.commit('bsp/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_ENCODER_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_INITIALCOMMENT_DEFAULT')
    this.$store.commit('bsp/SET_COMPLIANCEUPLOAD_DEFAULT')
    this.$store.commit('salesProspecting/SET_RADIO_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_CHECKBOX_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOTHERENTITY_DETAILS_DEFAULT')
  },
  methods: {
    async reloadList(){
        this.existingSalesProspect = []
        const res = await this.$store.dispatch('salesProspecting/doGetAllSalesProspecting')
        this.salesProspecting = res.data.result

        this.salesProspecting.map(item => {
            if (item.is_existing == 1 && item.status == 'Merged (IS)'){
                this.existingSalesProspect.push(item)
            }
        })
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    clearFields(){
        this.salesProspectingForm.account_name = ''
        this.salesProspectingForm.legal_entity_name = ''
        this.salesProspectingForm.trade_name = ''
        this.sp_no = ''
    },
    cancel(){
        this.dialog = false
        this.clearFields()
    },
    openDialog(e){
        this.dialog = true
        this.sp_no = e.id
        this.salesProspectingForm.account_name = e.sp_primary_details.account_name
    },
    completeProfile(){
        var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

        let origForm = {...this.salesProspectingForm};
        origForm.accept_or_negotiate = 'Accepts Partnership'
        delete origForm.scorecard
        delete origForm.scorecard_result
        delete origForm.id

        let finalPayload =  _.cloneDeep(origForm);
        finalPayload.is_draft = false

        var convertedFormData = jsonToFormData(finalPayload, options)
        const payload = {id: this.sp_no, payload: convertedFormData}	
        this.callSave(payload)
    },
    async callSave (payload) {
        const res = await this.$store.dispatch('salesProspecting/doSaveSalesProspecting', {payload})
        if(res.status === 200) {
            this.clearFields()
            this.dialog = false
            this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Profile Successfully Updated!','messagetype':'success'},{ root: true });
            this.reloadList()
        }
    },
  }
}
</script>