<template>
    <v-row class="mx-1">
        <!-- <v-btn class="mb-4" style="float:right;" @click="addWitness()" outlined color="primary"><v-icon>mdi-plus</v-icon>Add Witness</v-btn> -->
        <v-expansion-panels>
            <v-expansion-panel v-for="(parentItem, parentIndex) in existingWitnessUploaded" :key="parentIndex">
                <v-expansion-panel-header disable-icon-rotate>
                    <div>
                        <!-- <v-icon @click.stop="" @click="deleteWitness(index)" color="error" v-if="index > 0 ? 'mdi-delete' : ''">mdi-delete</v-icon> -->
                        <!-- <span class="mx-2">Witness {{index === 0 ?  '' : index+1}}</span> -->
                        <span class="mx-2">Witness</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="mb-3">
                        <v-col cols="3">
                            <v-text-field
                                label="First Name"
                                hide-details="auto"
                                v-model="parentItem.first_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Middle Name"
                                hide-details="auto"
                                v-model="parentItem.middle_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Last Name"
                                hide-details="auto"
                                v-model="parentItem.last_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Suffix"
                                hide-details="auto"
                                v-model="parentItem.suffix"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            >
                            <v-text-field
                                label="Position"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="parentItem.position"
                            ></v-text-field>
                        </v-col>  
                        <v-col cols="5">
                            <v-file-input
                                v-model="parentItem.valid_id"
                                label="ID"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                        </v-file-input>
                        </v-col>
                        <v-col :cols="1" class="mt-1" v-if="parentItem.valid_idUploaded">
                            <v-icon
                                @click="retrieveUploaded(parentItem.valid_idUploaded)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn 
                            v-if="parentItem.first_name && parentItem.middle_name && parentItem.last_name && parentItem.position"
                            class="mb-4" style="float:right;" @click="updateWitness(parentItem)" outlined color="primary"><v-icon>mdi-plus</v-icon>Update Witness</v-btn>
                            <v-btn 
                            v-else
                            class="mb-4" style="float:right;" disabled outlined color="primary"><v-icon>mdi-plus</v-icon>Update Witness</v-btn>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';
import _ from 'lodash';
export default {
    props: ["item"],
    computed: {
    ...mapGetters({
            witness: 'salesProspecting/witness',
            moa: 'salesProspecting/moa',
            existingWitnessUploaded: 'salesProspecting/existingWitnessUploaded',
        }),
    },
    methods: {
        async updateWitness(item){
            console.log(item)
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}

            let initialPayload = {...item}
            delete initialPayload.id
            delete initialPayload.sales_prospects_id
            delete initialPayload.updated_at
            delete initialPayload.created_at
            delete initialPayload.bod_id
            delete initialPayload.valid_idUploaded

            var convertedFormData = jsonToFormData(initialPayload, options)
            const payload = {id: this.item, payload: convertedFormData}
            const res = await this.$store.dispatch('salesProspecting/doUploadWitness', {payload})
			if (res.status === 200){
				this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
				this.realoadWitness()
                const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item)
                console.log(res)
			}
        },
        async realoadWitness () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item)
			this.decodeWitness(res.data.result[0].witness)
			this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
		},
        decodeWitness (resBod){
			let nonExpiry = {
                file: null,
            }
			resBod.forEach(function(e) {
				e.bod_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiry)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_WITNESS', resBod)
		},
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			console.log(res)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
    }
}
</script>
