<template>
    <v-row class="mx-1" v-if="existingWitnessUploaded.length == 0">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                    <div>
                        <span class="mx-2">Witness</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="mb-3">
                        <v-col cols="3">
                            <v-text-field
                                label="First Name"
                                hide-details="auto"
                                v-model="witness.first_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Middle Name"
                                hide-details="auto"
                                v-model="witness.middle_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Last Name"
                                hide-details="auto"
                                v-model="witness.last_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Suffix"
                                hide-details="auto"
                                v-model="witness.suffix"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            >
                            <v-text-field
                                label="Position"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="witness.position"
                            ></v-text-field>
                        </v-col>  
                        <v-col cols="6">
                            <v-file-input
                                v-model="witness.valid_id"
                                label="ID"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                        </v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn v-if="witness.first_name && witness.middle_name && witness.last_name && witness.position && witness.valid_id" 
                            class="mb-4" style="float:right;" @click="uploadWitness" outlined color="primary"><v-icon>mdi-plus</v-icon>Upload Witness</v-btn>
                            <v-btn v-else
                            class="mb-4" style="float:right;" disabled outlined color="primary"><v-icon>mdi-plus</v-icon>Upload Witness</v-btn>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';
import _ from 'lodash';
export default {
    props: ["item"],
    computed: {
    ...mapGetters({
            witness: 'salesProspecting/witness',
            moa: 'salesProspecting/moa',
            existingWitnessUploaded: 'salesProspecting/existingWitnessUploaded',
        }),
    },
    methods: {
        async uploadWitness(){
            console.log(this.witness)
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
            var convertedFormData = jsonToFormData(this.witness, options)
            const payload = {id: this.item, payload: convertedFormData}
            const res = await this.$store.dispatch('salesProspecting/doUploadWitness', {payload})
			if (res.status === 200){
				this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
				this.realoadWitness()
                const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item)
                console.log(res)
			}
        },
        async realoadWitness () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item)
			this.decodeWitness(res.data.result[0].witness)
			this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
		},
        decodeWitness (resBod){
			let nonExpiry = {
                file: null,
            }
			resBod.forEach(function(e) {
				e.bod_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiry)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_WITNESS', resBod)
		},
    }
}
</script>
