<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      width="1200px"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          v-on="on"
          small
          @click="getData"
        >
        <v-icon>mdi-file-document-multiple-outline</v-icon>
        </v-btn>
      </template>

    <v-card>
        <v-card-title>
          <span class="text-h5" id="dialog_title">Generate MOA</span>
        </v-card-title>
        <v-form ref="moaUpload" v-model="moaUpload">
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <h4>Type</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            >
                            <v-autocomplete
                                dense
                                solo
                                :items="items"
                                placeholder="Select MOA Type"
                                v-model="moa.moa_type"
                            >
                                
                            </v-autocomplete>
                        </v-col>
                    </v-row>


                <div v-if="moa.moa_type == 'AVP'">
                <AVP :item="item"> </AVP>
                </div>

                <div v-else>
                    <v-row>
                        <v-col
                            cols="4"
                            sm="6"
                            md="4"
                            >
                            <v-text-field
                                label="Day of Execution"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="genericRule"
                                v-model="moa.day_execution"
                            ></v-text-field>
                        </v-col> 
                        <v-col
                            cols="4"
                            sm="6"
                            md="4"
                            >
                            <v-text-field
                                label="Month of Execution"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.month_execution"
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                        <v-col
                            cols="4"
                            sm="6"
                            md="4"
                            >
                            <v-text-field
                                label="Year of Execution"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.year_execution"
                                type="number"
                                hide-spin-buttons
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                    </v-row>

                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            >
                            <v-text-field
                                label="Address/Location of Execution"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.address_execution"
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                    </v-row>

                    <v-row>
                        <v-col
                            v-if="moa.moa_type != 'WU_SU-REP'"
                            cols="4"
                            sm="6"
                            md="4"
                            >
                            <v-text-field
                                label="Appendices"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.appendices"
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                        <v-col
                            cols="4"
                            sm="6"
                            md="4"
                            >
                            <v-text-field
                                label="Service Fee Percent (in words)"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.service_fee_words"
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                        <v-col
                            cols="4"
                            sm="6"
                            md="4"
                            >
                            <v-text-field
                                label="Service Fee Percent (in figures)"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.service_fee_figures"
                                type="number"
                                hide-spin-buttons
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                    </v-row>

                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            >
                            <v-text-field
                                label="Period of Initial Term of Agreement (in months)"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.period_term_months"
                                type="number"
                                hide-spin-buttons
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            >
                            <v-text-field
                                label="Period of Initial Term of Agreement (in years)"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.period_term_years"
                                type="number"
                                hide-spin-buttons
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                    </v-row>


                    <v-row>
                        <v-col>
                            <h4>Sub-representative</h4>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            cols="3"
                            sm="6"
                            md="3"
                            >
                            <v-text-field
                                label="First Name"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.sub_representative.first_name"
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col> 
                        <v-col
                            cols="3"
                            sm="6"
                            md="3"
                            >
                            <v-text-field
                                label="Middle Name"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.sub_representative.middle_name"
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col>  
                        <v-col
                            cols="3"
                            sm="6"
                            md="3"
                            >
                            <v-text-field
                                label="Last Name"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.sub_representative.last_name"
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col>  
                        <v-col
                            cols="3"
                            sm="6"
                            md="3"
                            >
                            <v-text-field
                                label="Suffix"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.sub_representative.suffix"
                            ></v-text-field>
                        </v-col>  
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            >
                            <v-text-field
                                label="Position"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="moa.sub_representative.position"
                                :rules="genericRule"
                            ></v-text-field>
                        </v-col>  
                    </v-row>
                </div>

                <br>

                <div>
                    <v-row>
                        <v-col>
                            <h4>Witness</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <ViewUploadedMoaWitness :item="item"> </ViewUploadedMoaWitness>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <MoaWitness :item="item"> </MoaWitness>
                        </v-col>
                    </v-row>
                    <v-row v-if="existingSignatoryUploaded.length != 0" class="mt-5">
                        <v-col>
                            <h4>Signatory</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <ViewUploadedMoaSignatory :item="item"> </ViewUploadedMoaSignatory>
                        </v-col>
                    </v-row>
                    <v-row v-if="existingSignatoryUploaded.length == 0" class="mt-5">
                        <v-col>
                            <h4>Signatory</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <MoaSignatory :item="item"> </MoaSignatory>
                        </v-col>
                    </v-row>
                </div>

                </v-container>
        </v-card-text>
    </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined color="primary"
            text
            @click="cancel()"
          >
            Cancel
          </v-btn>

            <v-btn
            v-if="moa.moa_type == 'AVP'"
            color="success"
            @click="sendEmail"
            >
            EMAIL AVP MOA
            </v-btn>

            <v-btn
            v-if="moa.moa_type == 'AVP'"
            color="primary"
            @click="generate"
            >
            Generate AVP MOA
            </v-btn>
            

            <v-btn
            v-if="moa.moa_type == 'WU_SU-REP'"
            color="primary"
            @click="generate"
            >
            Generate WU_SU MOA
            </v-btn>

            <v-btn
            v-if="moa.moa_type == 'NONEX'"
            color="primary"
            @click="generate"
            >
            Generate NONEX
            </v-btn>
                        
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import MoaWitness from '@/components/modules/salesProspecting/MoaWitness.vue'
import ViewUploadedMoaWitness from '@/components/modules/salesProspecting/ViewUploadedMoaWitness.vue'
import MoaSignatory from '@/components/modules/salesProspecting/MoaSignatory.vue'
import AVP from '@/components/modules/salesProspecting/AVP.vue'
import ViewUploadedMoaSignatory from '@/components/modules/salesProspecting/ViewUploadedMoaSignatory.vue'
import _ from 'lodash';

  export default {
    components: {
		MoaWitness: MoaWitness,
		ViewUploadedMoaWitness: ViewUploadedMoaWitness,
		MoaSignatory: MoaSignatory,
		ViewUploadedMoaSignatory: ViewUploadedMoaSignatory,
		AVP: AVP,
	},
    props: ["item"],
    computed: {
    ...mapGetters({
            moa: 'salesProspecting/moa',
            branches: 'salesProspecting/branches',
            countries: 'salesProspecting/countries',
            cities: 'salesProspecting/cities',
            existingSignatoryUploaded: 'salesProspecting/existingSignatoryUploaded',
            existingWitnessUploaded: 'salesProspecting/existingWitnessUploaded',
            genericRule: 'salesProspecting/genericRule',
            avp_details: 'salesProspecting/avp_details',
        }),
	},
    data () {
      return {
        dialog: false,
        items: ['WU_SU-REP','NONEX', 'AVP'],
        moaUpload: true,
      }
    },
    methods:{
        async getData(){
            const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item)
            this.decodeWitness(resSP.data.result[0].witness)
            this.decodeSignatory(resSP.data.result[0].signatory)
            this.assignAppForm(resSP.data.result[0].sp_primary_business_address)
            this.assignAccountName(resSP.data.result[0].sp_primary_details)
            if (resSP.data.result[0].avp_moa != null){
				this.decodeAvp(resSP.data.result[0].avp_moa.fields)
			}
            this.assignAvpData(resSP.data.result[0])

            let currentDate = new Date().toJSON().slice(0, 10);
            

            let day_execution = currentDate.slice(8 ,10)
            console.log(day_execution + 'th')
            this.moa.day_execution = day_execution + 'th'

            let year_execution = currentDate.slice(2,4)
            this.moa.year_execution = year_execution

            let month_execution = currentDate.slice(5,7)
            this.moa.month_execution = this.getMonthName(month_execution)

            // let midnight_date_month = this.getMonthName(month_execution)
            let midnight_date_day = day_execution -1
            console.log(midnight_date_day)

            let midnight_date_year = parseInt(currentDate.slice(0,4))
            console.log(midnight_date_year + 1)

            let midnight_date = this.getMonthName(month_execution) + ' ' + midnight_date_day + ', ' + (midnight_date_year + 1)

            this.moa.midnight_date = midnight_date

            let full_year = currentDate.slice(0,4)
            console.log(full_year)

            let cover_date = this.getMonthName(month_execution) + ' ' + day_execution + ', ' + full_year

            this.moa.cover_date_header = cover_date

        },
        assignAvpData(e){
            const payload = {
                primary_business_address: e.sp_primary_business_address,
                primary_details: e.sp_primary_details
            }
            this.$store.commit('salesProspecting/SET_AVP_DETAILS', payload)
            console.log(this.avp_details)

        },
        getMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);

            return date.toLocaleString('en-US', { month: 'long' });
        },
        decodeWitness (resBod){
			let nonExpiry = {
                file: null,
            }
			resBod.forEach(function(e) {
				e.bod_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiry)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_WITNESS', resBod)
		},
        decodeSignatory (resBod){
			let nonExpiry = {
                file: null,
            }
            let expiryMenu = {
				menuIssued: false,
                menuExpiry: false
			}
			resBod.forEach(function(e) {
				e.signatory_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiry)
                e.menu = _.clone(expiryMenu)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY', resBod)
		},
        decodeAvp (res){
            const avpDetails = JSON.parse(res)
            avpDetails.pet_menuIssued = false
            avpDetails.sub_representative.menuIssued = false
			this.$store.commit('salesProspecting/SET_MOA', avpDetails)
		},
        assignAppForm(resBod){
            this.moa.app_form.address_line_1 = resBod.address_line_1
            this.moa.app_form.address_line_2 = resBod.address_line_2
            this.moa.app_form.country = resBod.country
            this.moa.app_form.province = resBod.province
            this.moa.app_form.city = resBod.city
            this.moa.app_form.region = resBod.region
            this.moa.app_form.postal_code = resBod.postal_code
            this.moa.app_form.postal_code = resBod.postal_code
            this.moa.app_form.landline = resBod.landline
            this.moa.app_form.cellphone = resBod.cellphone
        },
        assignAccountName(resBod){
            this.moa.app_form.account_name = resBod.account_name
        },
        async generate(){
            
            if (!this.moa.to_email){
                this.moa.to_email = []
            } 
            if (!this.moa.cc_email){
                this.moa.cc_email = []
            } 
            if (!this.moa.bcc_email){
                this.moa.bcc_email = []
            }

            if (this.moa.other_products_array){
                let otherProd = this.moa.other_products_array.join(', ')
                this.moa.other_products = otherProd
            }
            this.moa.is_send = false
            this.moa.is_op_req_only = false

            if (this.moa.is_dengue === '1'){
                this.moa.is_dengue_generated = true
            }
            if (this.moa.is_payapa === '1'){
                this.moa.is_payapa_generated = true
            }
            if (this.moa.is_malayan == '1'){
                this.moa.is_malayan_generated = true
            }
            if (this.moa.is_visa_selling == '1'){
                this.moa.is_visa_selling_generated = true
            }
            if (this.moa.is_visa_cashin == '1'){
                this.moa.is_visa_cashin_generated = true
            }
            if (this.moa.is_gcash_in == '1'){
                this.moa.is_gcash_in_generated = true
            }
            if (this.moa.is_gcash_out == '1'){
                this.moa.is_gcash_out_generated = true
            }
            if (this.moa.is_paymaya == '1'){
                this.moa.is_paymaya_generated = true
            }
            if (this.moa.is_coins == '1'){
                this.moa.is_coins_generated = true
            }
            if (this.moa.is_dragonpay == '1'){
                this.moa.is_dragonpay_generated = true
            }
            if (this.moa.is_unionbank == '1'){
                this.moa.is_unionbank_generated = true
            }
            if (this.moa.is_eload == '1'){
                this.moa.is_eload_generated = true
            }
            if (this.moa.is_airline == '1'){
                this.moa.is_airline_generated = true
            }
            if (this.moa.is_bills_pay == '1'){
                this.moa.is_bills_pay_generated = true
            }

            this.$store.commit('salesProspecting/SET_RULE_GENERIC')

            if (this.moa.moa_type == 'WU_SU-REP'){
                if (this.existingWitnessUploaded.length == 0){
                    this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Upload at least one witness!','messagetype':'error'},{ root: true });
                } else {
                    setTimeout(() => {
                        if (this.$refs.moaUpload.validate()) {
                            var initialPayload = this.moa
                            this.generateMoa(initialPayload)
                        } else {
                            this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
                        }
                    })
                }
            }

            if (this.moa.moa_type == 'NONEX'){
                if (this.existingWitnessUploaded.length == 0){
                    this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Upload at least one witness!','messagetype':'error'},{ root: true });
                } else {
                    setTimeout(() => {
                        if (this.$refs.moaUpload.validate()) {
                            var initialPayload = this.moa
                            this.generateMoa(initialPayload)
                        } else {
                            this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
                        }
                    })
                }
            }

            if (this.moa.moa_type == 'AVP'){
                if (this.existingWitnessUploaded.length == 0){
                    this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Upload at least one witness!','messagetype':'error'},{ root: true });
                } else if (this.existingSignatoryUploaded.length == 0){
                    this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Upload at least one signatory!','messagetype':'error'},{ root: true });
                } else {
                    setTimeout(() => {
                        if (this.$refs.moaUpload.validate()) {
                            var initialPayload = this.moa
                            this.generateMoa(initialPayload)
                        } else {
                            this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
                        }
                    })
                }
            }
        },
        async sendEmail(){
            
            if (!this.moa.to_email){
                this.moa.to_email = []
            } 
            if (!this.moa.cc_email){
                this.moa.cc_email = []
            } 
            if (!this.moa.bcc_email){
                this.moa.bcc_email = []
            }

            if (this.moa.other_products_array){
                let otherProd = this.moa.other_products_array.join(', ')
                this.moa.other_products = otherProd
            }
            this.moa.is_send = true
            this.moa.is_op_req_only = false
            
            if (this.moa.is_dengue === '1'){
                this.moa.is_dengue_generated = true
            }
            if (this.moa.is_payapa === '1'){
                this.moa.is_payapa_generated = true
            }
            if (this.moa.is_malayan == '1'){
                this.moa.is_malayan_generated = true
            }
            if (this.moa.is_visa_selling == '1'){
                this.moa.is_visa_selling_generated = true
            }
            if (this.moa.is_visa_cashin == '1'){
                this.moa.is_visa_cashin_generated = true
            }
            if (this.moa.is_gcash_in == '1'){
                this.moa.is_gcash_in_generated = true
            }
            if (this.moa.is_gcash_out == '1'){
                this.moa.is_gcash_out_generated = true
            }
            if (this.moa.is_paymaya == '1'){
                this.moa.is_paymaya_generated = true
            }
            if (this.moa.is_coins == '1'){
                this.moa.is_coins_generated = true
            }
            if (this.moa.is_dragonpay == '1'){
                this.moa.is_dragonpay_generated = true
            }
            if (this.moa.is_unionbank == '1'){
                this.moa.is_unionbank_generated = true
            }
            if (this.moa.is_eload == '1'){
                this.moa.is_eload_generated = true
            }
            if (this.moa.is_airline == '1'){
                this.moa.is_airline_generated = true
            }
            if (this.moa.is_bills_pay == '1'){
                this.moa.is_bills_pay_generated = true
            }

            this.$store.commit('salesProspecting/SET_RULE_GENERIC')

            if (this.moa.moa_type == 'AVP'){
                if (this.existingWitnessUploaded.length == 0){
                    this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Upload at least one witness!','messagetype':'error'},{ root: true });
                } else if (this.existingSignatoryUploaded.length == 0){
                    this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Upload at least one signatory!','messagetype':'error'},{ root: true });
                } else {
                    setTimeout(() => {
                        if (this.$refs.moaUpload.validate()) {
                            var initialPayload = this.moa
                            this.generateMoa(initialPayload)
                        } else {
                            this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
                        }
                    })
                }
            }
        },
        async generateMoa(initialPayload){
            const payload = {id: this.item, payload: initialPayload}
            const res = await this.$store.dispatch('salesProspecting/doGenerateMoa', {payload})
            if(res.status === 200) {
                this.$store.commit('salesProspecting/UNSET_RULE_GENERIC')
                this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
            }
            if (initialPayload.is_send == false){
                this.pdfConvert(res.data.result)
            }
        },
        pdfConvert (base64String) {
            var decoded = Buffer.from(base64String, 'base64')
            var blob = new Blob([decoded], { type: 'application/pdf' })
            var fileURL = URL.createObjectURL(blob)
            window.open(fileURL)
        },
        cancel(){
            this.dialog = false
            this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
            this.$store.commit('salesProspecting/SET_EXISTING_WITNESS_DEFAULT')
            this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
            this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY_DEFAULT')
            this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
            this.$store.commit('salesProspecting/SET_AVP_DETAIL_DEFAULT')
        }
    },
  }
</script>

<style scoped>
    #dialog_title{
        color: rgb(20, 20, 137);
        font-weight: 600;
    }
</style>