<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      width="900px"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="red"
            text
            v-bind="attrs"
            v-on="on"
            small
            >
            <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
      </template>
        <v-card>
            <v-card-title>
            <span class="text-h5" id="dialog_title">File Deletion</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                      <v-row>
                          <v-col>
                              <h4>Reason for Deletion</h4>
                          </v-col>
                      </v-row>
                          <v-row>
                <v-col>
                  <v-textarea
                    label="Type Here"
                    v-model="comment"
                    auto-grow
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              </v-container>
          </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                outlined color="primary"
                text
                @click="cancel"
            >
                Cancel
            </v-btn>
            <v-btn
                v-if="comment"
                color="primary"
                @click="submit"
            >
                File Deletion
            </v-btn>
            <v-btn
                v-else
                color="primary"
                disabled
            >
                File Deletion
            </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ["item"],
    data () {
      return {
        comment: '',
        dialog: false,
      }
    },
    methods:{
        cancel(){
            this.dialog = false
            this.comment = ''
        },
        async submit(){
            const payload = {
              id: this.item,
              finalPayload: {
                reason: this.comment
              }
            }
            const res = await this.$store.dispatch('salesProspecting/doFileTermination', {payload})

            if (res.status === 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Deletion Filed!','messagetype':'success'});
                this.dialog = false
                location.reload();
            }
        }
    },
  }
</script>

<style scoped>
    #dialog_title{
        color: rgb(20, 20, 137);
        font-weight: 600;
    }
</style>